<template>
  <div class="main">
    <img class="banner" src="http://www.szxcd.com.cn/content/img/indexbanner.png" alt="">
    <div class="views">
      <div class="viewContainer">
        <div class="" v-for="(item,index) in list" :key="index">
          <div class="container">
            <img class="titleImg" :src="item.titleImg" alt="">
            <p class="subtitle">{{item.subtitle}}</p>
            <el-carousel height="625px">
              <el-carousel-item v-for="(img,index) in item.imgs" :key="index">
                <img :src="img" alt="" class="carouselItem">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'factoryView',
  components: {
  },
  data(){
    return {
      list:[
        {
          titleImg:'http://www.szxcd.com.cn/content/img/viewtitle.jpeg',
          subtitle:'测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字',
          imgs:['http://www.szxcd.com.cn/content/img/banner.jpeg','http://www.szxcd.com.cn/content/img/aboutbanner.jpg',

          ]
        },
        {
          titleImg:'http://www.szxcd.com.cn/content/img/viewtitle2.jpeg',
          subtitle:'测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字',
          imgs:['http://www.szxcd.com.cn/content/img/banner.jpeg','http://www.szxcd.com.cn/content/img/aboutbanner.jpg',

          ]
        }
      ]
    }
  },
  created () {
    window.scrollTo(0,0)
  }
}
</script>

<style scoped>
.subtitle{
  font-size: 18px;
  color: #999;
  width: 900px;
  line-height: 30px;
  margin: 10px auto 45px;
}
.viewContainer>div{
  padding: 40px 0 20px;
}
.viewContainer>div:nth-child(odd){
  background-color: #f3f3f3;
}
.viewContainer>div:nth-child(even){
  background-color: #fff;
}
.carouselItem{

}
.titleImg{
  display: block;
  margin: 0 auto;
}
.banner{
  width: 1560px;
  height: 630px;
  max-width: 100%;
  font-size: 0;
  display: block;
  margin: 0 auto;
}

</style>

<template>
  <div class="main">
    
    <div class="container">
      <p style="margin-top:10px">
        <router-link class="position" :to="{name:'index',params:{index:0}}">首页 </router-link>
        <span class="position">&gt;&gt; 新闻中心</span>
       
      </p>
      <div class="newscontainer">
        <div class="newscontainerL">
          <div class="newscontainerL1">新闻中心</div>
          <div class="newscontainerL2 active" v-for="(item,index) in typelist" :key="index">{{item.name}}</div>
        </div>
        <div class="newscontainerR">
          <router-link :to="{name:'newsinfo',params:{parentname:'新闻中心',name:item.title,index:5}}" class="newitem" v-for="(item,index) in list" :key=index>
            <p class="newstitle">{{item.title}}</p>
            <p class="newstime">{{item.time}}</p>
          </router-link>
        </div>
        <div class="pageclass">
          <el-pagination
            current-page.sync="1"
            background
            prev-text="上一页"
            next-text="下一页"
            :page-size="20"
            layout="total, prev, pager, next"
            :total="7">
          </el-pagination>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'news',
  components: {
  },
  data(){
    return{
      typelist:[
        {
          type:0,
          name:'新闻中心'
        },
      ],
      list:[
        {
          title:'aksdakjsdjahkdajhdskasjdhakdjahksdjahjas',
          time:'2020-01-01',
        },
        {
          title:'啊塞打螺丝大胜靠德垃圾啊塞打螺丝大胜靠德垃达',
          time:'2022-12-12'
        },
        {
          title:'aksdakjsdjahkdajhdskasjdhakdjahksdjahjas',
          time:'2020-01-01'
        },
        {
          title:'啊塞打螺丝大胜靠德垃圾漏打卡进啦卢卡斯到家啦大家啊离开阿斯达',
          time:'2022-12-12'
        },
        {
          title:'aksdakjsdjahkdajhdskasjdhakdjahksdjahjas',
          time:'2020-01-01'
        },
        {
          title:'啊塞打螺丝大胜靠德垃圾漏打卡进啦卢卡斯到家啦大家啊离开阿斯达',
          time:'2022-12-12'
        },
        {
          title:'aksdakjsdjahkdajhdskasjdhakdjahksdjahjas',
          time:'2020-01-01'
        },
      ]
    }
  },
  watch: {
      
  },
  created () {
    window.scrollTo(0,0)
  },
}
</script>

<style scoped>
.newstitle{
  width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left !important;
}
.newstime{
  width: 100;
  text-align: right;
}
::v-deep .el-pagination .btn-next{
  padding: 0 10px;
}
::v-deep .el-pagination .btn-prev{
  padding: 0 10px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #fad749;
}
.pageclass{
  width: 910px;
  margin-left: 290px;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.newitem img{
  width: 210px;
  height: 210px;
  object-fit: cover;
}
.newitem p{
  line-height: 43px;
  font-size: 16px;
  color: #444;
  text-align: center;
}
.newitem:hover .newstitle{
  color:#fad749;
  transition: all .5s;
}
.newitem{
  font-size: 0;
  height: 50px;
  border-bottom: 1px solid #ccc; 
  cursor: pointer;
  transition: all .5s;
  display: flex;
  justify-content: space-between;
}
.newscontainerL2{
  cursor: pointer;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  background-color: #70b9ec;
  margin-bottom: 1px;
}
.newscontainerL1{
  line-height: 80px;
  font-size: 30px;
  background-color: #000;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}
.newscontainerL{
  width: 270px;
}
.newscontainerR{
  width: 900px;
}
.newscontainer{
  display: flex;
  margin-top: 34px;
  justify-content: space-between;
  flex-wrap: wrap;
}
a{
  text-decoration: none;
  font-size: 14px;
  color: #666;
}
.carouselItem{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active{
  background-color: #ffd800;
}
.position{
  font-size: 14px;
  color: #666;
}
</style>
